import wpImageSize from '@/enums/wpImageSize';
import wpImageSizeCollectionType from '@/enums/wpImageSizeCollectionType';

const wpImageSizeCollection = {};

wpImageSizeCollection[wpImageSizeCollectionType.EXTRA_BIG] = [
  wpImageSize.MEDIUM_LARGE,
  wpImageSize.LARGE,
  wpImageSize.EXTRA_LARGE,
];

wpImageSizeCollection[wpImageSizeCollectionType.BIG] = [
  wpImageSize.SMALL,
  wpImageSize.MEDIUM,
  wpImageSize.MEDIUM_LARGE,
  wpImageSize.LARGE,
];

wpImageSizeCollection[wpImageSizeCollectionType.MEDIUM] = [
  wpImageSize.SMALL,
  wpImageSize.MEDIUM,
  wpImageSize.MEDIUM_LARGE,
];

wpImageSizeCollection[wpImageSizeCollectionType.SMALL] = [
  wpImageSize.THUMB_BIGGER,
  wpImageSize.SMALL,
  wpImageSize.MEDIUM,
];

wpImageSizeCollection[wpImageSizeCollectionType.LOGO] = [wpImageSize.LOGO];

export default wpImageSizeCollection;
