const wpImageSize = {
  LOGO: 'logos',
  THUMB_BIGGER: 'thumb_bigger',
  SMALL: 'small',
  MEDIUM: 'medium',
  MEDIUM_LARGE: 'c_medium_large',
  LARGE: 'large',
  EXTRA_LARGE: '1536x1536',
};

export default wpImageSize;
